import React from 'react'
import './footer.css'
import { tr } from '../../global-pure.js'

const Footer = () => {
  return (
    <div className="dnc__footer footer-center" id="footer">
      {tr('Copyright © {years}. All rights reserved.')}
    </div>
  )
}

export default Footer