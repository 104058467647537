import { React } from 'react'
// import './services.css'
import { icap, tr, slug } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout'
import { card as gnbCard } from './gnb/Job.jsx'
import { card as milkCard } from './milk/Job.jsx'
import Card from '../../../components/card/Card'
import cardPic from '../../../assets/courses/distros.png'

export const card = {
  title: 'delivery:title',
  blurb: 'delivery:blurb',
  subtitle: 'delivery:subtitle',
  routeBuilder: () => `/${slug(tr('jobs'))}/${slug(tr('delivery'))}`,
  imgUrl: cardPic,
  call: 'see jobs',
  // ask: <AskLink text={'purchase now'} prefill={'ant-s19j:question'} />
}

const Delivery = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr('delivery jobs'))}</h1>
        <Blog />
      </div>
    </PageLayout>
  )
}

const Blog = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Card card={gnbCard} />
        <Card card={milkCard} />
      </div>
    </div>
  )
}

export default Delivery