import { React } from 'react'
// import './services.css'
import { icap, tr } from '../../global-pure.js'
import PageLayout from '../../containers/page/PageLayout'
import { card as deliveryCard } from './delivery/Jobs.jsx'
import Card from '../../components/card/Card'

const Jobs = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr('jobs'))}</h1>
        <Blog />
      </div>
    </PageLayout>
  )
}

const Blog = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Card card={deliveryCard} />
      </div>
    </div>
  )
}

export default Jobs