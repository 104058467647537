import { useState } from 'react'
import { tr, icap } from '../../global-pure.js'

export function useEvents(invoiceKey) {
  const [events, setEvents] = useState([])
  const [fetched, setFetched] = useState(false)
  function strip(text) {
    return text.toLowerCase().replace(/^[ -]*|[ -]*$/g, '')
  }
  if (!fetched) {
    setFetched(true)
    fetch(`https://lnbits.satoshidnc.com/events/api/v1/events?api-key=${invoiceKey}`, {
      headers: {
        accept: 'application/json'
      }
    }).then(r => {
      if (r.ok) {
        r.json().then(json => {
          setEvents(json.map(e => {
            const eventTitle = e.info.split('\n').filter(line => line.startsWith('𝗘𝘃𝗲𝗻𝘁𝗼') || strip(line).startsWith('evento:')).map(h => h.substring(h.indexOf(':')+1).trim())[0]
            const eventHours = e.info.split('\n').filter(line => line.startsWith('𝗛𝗼𝗿𝗮') || strip(line).startsWith('hora:')).map(h => h.substring(h.indexOf(':')+1).trim())[0]?.split('-').map(f => f.trim()).map(h => h.length <= 5? h+':00': h) || [`00:00:00`, `23:59:59`]
            const event = {
              id: e.id,
              title: tr(eventTitle||e.name||'Untitled'),
              start: new Date(Date.parse(`${e.event_start_date}T${eventHours[0]}`)),
              end: new Date(Date.parse(`${e.event_end_date}T${eventHours[1]}`)),
            }
            console.log(event)
            return event
          }))
        })
      }
    }).catch()
  }
  return events
}